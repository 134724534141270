/*
  meta site package API
 */
import axios from 'axios';
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

export default {
  getRoomListData(roomName) {
    return http.get(`/glb/list/${roomName}`)
    .then(response=> {
      return response.data;
    })
  }
};
