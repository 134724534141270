<template>
  <a-entity class="room-scene">
    <!-- VRボタン -->
    <a-entity
      id="myEnterARButton"
      href="#"
    >
      <a
        class="btn-flat-border"
        href="#"
      >AR</a>
    </a-entity>
    <!--カメラ-->
    <a-entity
      id="rig"
      ref="rig"
      movement-controls="speed:0;"
      kinematic-body="radius:0.5"
      far="50000"
      :position="jsonData.rig.position"
      :rotation="jsonData.rig.rotation"
      position-listener
    >
      <a-entity
        ref="camera"
        camera
        look-controls="touchEnabled: true; magicWindowTrackingEnabled: false; touchZoom: false; touchPan: false; pitchEnabled: true;"
        position="0 1.35 0"
      />
    </a-entity>
    <!-- 基本設定 -->
    <a-entity id="basic-parts">
      <!-- 天球 -->
      <a-sky
        :radius="jsonData.skyData.radius"
        :src="imageRoutePath + jsonData.skyData.image"
        :position="jsonData.skyData.position"
        :rotation="jsonData.skyData.rotation"
      />
      <!-- 床 -->
      <a-plane
        static-body
        position="0 -0.01 0"
        rotation="-90 0 0"
        :width="jsonData.skyData.radius * 2"
        :height="jsonData.skyData.radius * 2"
        material="opacity:0"
      />
      <!-- ライト -->
      <a-light
        type="ambient"
        color="#FFF"
        intensity="1"
      />
      <a-light
        type="directional"
        color="#999999"
        intensity="0.15"
        position="0 5.5 0"
      />
    </a-entity>
    <!-- 建物パーツ -->
    <a-entity id="glb-parts">
      <a-entity
        v-for="(common, index) in roomData.common"
        :id="index"
        :ref="index"
        :key="index"
        :gltf-model="common.path"
        :static-body="common.staticBody"
        model-loader
      />
      <!-- アニメーションパーツパーツ -->
      <a-entity
        v-for="(animation, index) in roomData.animation"
        :id="index"
        :key="index"
        :gltf-model="animation.path"
        :static-body="animation.staticBody"
        model-loader
      />
      <!-- 画像パーツ -->
      <a-image
        v-for="(image, index) in jsonData.imageData"
        :id="index"
        :key="index"
        :src="imageRoutePath + image.file"
        :width="image.width"
        :height="image.height"
        :position="image.position"
        :rotation="image.rotation"
      />
      <!-- リンクパーツ -->
      <a-entity
        v-for="(glb, index) in roomData.link"
        :id="index"
        :key="index"
        :gltf-model="glb.path"
        :static-body="glb.staticBody"
        model-loader
        @click="clickLink(index)"
      />
    </a-entity>
  </a-entity>
</template>

<script>
import api from '../api';
const uri = new URL(window.location.href);

export default {
  name: 'RoomScene',
  props: {
    imageRoutePath: { // 建物ごとの画像のルートパス
      type: String,
      default: ''
    },
    glbRoutePath: { // 建物ごとのGLBのルートパス
      type: String,
      default: ''
    },
    jsonData: {       // settingJsonのデータ
      type: Object,
      default: function () { return {} }
    }
  },
  data() {
    return {
      roomPath: uri.origin,
      roomData: {},
      loadingFlag: false,
      roomCount: 0,
      modelCount: 0
    }
  },
  watch: {
    /*******************************************
     * animationパーツとlinkパーツがある場合は親で設定
     ******************************************/
    roomData(newObject) {
      if(Object.keys(newObject.animation).length > 0 || 
        Object.keys(newObject.link).length > 0
      ) {
        this.$emit('setGlbData', newObject);
      }
    }
  },
  beforeCreate() {
    // THREE.jsの警告が出続けるので無効化
    console.warn = () => {}; // eslint-disable-line no-console
    // Fキー無効
    document.addEventListener('keyup', event => {
      if (event.code === 'KeyF') {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    });
    // 右クリック無効
    document.oncontextmenu = function () { return false; }
  },
  computed: {
    isTouchDevice() {
      // タッチデバイス判定を取得
      return this.$store.getters['CampusStatus/getTouchDeviceStatus'];
    }
  },
  mounted() {
    // URLからルーム名を取得
    // const roomName = this.$route.path.replace('/', '');
    const roomName = this.$route.name;
    this.createRoomData(roomName);
    // a-sceneに属性を設定
    this.setScene();
    /*******************************************
     * Loading画面用の処理
     ******************************************/
    // ロード済のモデルカウント関数
    const changeModelCount = () => {
      this.modelCount++;
      if(this.modelCount === this.roomCount) {
        // 全てのロードが完了したら親Viewのload処理を実行
        this.$emit('loadFlag');
        // GLBのロードが完了したら動けるようにスピードを設定
        const rigElement = document.getElementById('rig');
        if(this.isTouchDevice) {
          // タッチデバイスの場合はspeedを0のままにする
          rigElement.setAttribute('movement-controls', 'speed:0;');
        } else {
          // 非タッチデバイスの場合は通常の速度を設定
          rigElement.setAttribute('movement-controls', 'speed:0.18;');
        }
      }
    };
    // 建物データロードイベント
    AFRAME.registerComponent('model-loader', {
      init: function () {
        this.el.addEventListener('model-loaded', function () {
          changeModelCount();
          console.log('Finish model load');
        });
      }
    });
  },
  methods: {
    clickLink(index){
      this.$emit('setConfirmWindow', index)
    },
    /*******************************************
     * a-sceneに属性を設定
     ******************************************/
    setScene() {
      const scene = document.getElementsByTagName('a-scene');
      scene[0].setAttribute('auto-enter-vr', '');
      scene[0].setAttribute('physics', 'debug:false');
      scene[0].setAttribute('embedded', '');
      scene[0].setAttribute('renderer', 'antialias: true');
      scene[0].setAttribute('vr-mode-ui', 'enabled: false; enterARButton: #myEnterARButton;');
      scene[0].setAttribute('device-orientation-permission-ui', 'enabled: false');
      scene[0].setAttribute('cursor', 'rayOrigin: mouse');
    },
    /*******************************************
     * サーバーにある建物glbを全て取得し、整形する
     * @param roomName 建物名
     * @return 各パーツごとに分類わけした建物情報を返却
     ******************************************/
    createRoomData(roomName) {
      // S3から建物データ一覧を取得
      api.getRoomListData(roomName)
      .then(roomList => {
        let roomData = {
          common: {},
          animation: {},
          link: {}
        }
        // 取得したglbの数を格納
        // this.roomCount = Object.keys(roomList).length;
        // 対称のGLBディレクトリを
        const glbDirName = this.glbRoutePath.slice( 1 ) + this.jsonData.modelName;
        // Glbファイルを取得
        for(const property in roomList){
          // 子階層のページが存在する場合はページ名のglbだけを処理する
          if(this.$route.params.page) {
            if (roomList[property].path.indexOf(glbDirName) === -1) continue;
          }
          this.roomCount++;
          let body = '';
          let glbName = roomList[property].name;
          // 当たり判定なしパーツ
          if (glbName.match('nobody')) {
            body = 'shape:none;';
          }
          // アニメーションパーツ
          if (glbName.match('animation')) {
            roomData.animation[glbName] = {
              path: this.roomPath + '/' + roomList[property].path,
              staticBody: body
            }
            continue;
          }
          if (glbName.match('link')) {
            roomData.link[glbName] = {
              path: this.roomPath + '/' + roomList[property].path,
              staticBody: body
            }
            continue;
          }
          // 共通パーツ
          roomData.common[glbName] = {
            path: this.roomPath + '/' + roomList[property].path,
            staticBody: body
          }
        }
        this.roomData = roomData;
        // 建物反映
        let vm = this;
        vm.$forceUpdate();
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#myEnterARButton {
  display: none;
  min-width: 56px;
  min-height: 40px;
  right: 0;
  bottom: 38px;
  position: absolute;
}
</style>