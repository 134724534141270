<template>
  <div class="confirm-window">
    <p>現在のページから移動します。</p>
    <div class="target-info">
      <p class="target-title">
        {{ targetTitle }}
      </p>
      <p class="target-url">
        遷移先：{{ targetUrl }}
      </p>
    </div>
    <button
      class="move-button ok-col"
      @click="pageMove"
    >
      OK
    </button>
    <button
      class="move-button cancel-col"
      @click="$emit('close-window')"
    >
      キャンセル
    </button>
  </div>
</template>

<script>
export default {
  name: 'ConfirmWindow',
  props: {
    targetTitle: { // 移動先URL
      type: String,
      default: ''
    },
    targetUrl: { // 移動先URL
      type: String,
      default: ''
    },
    windowOpen: { // 別タブ起動
      type: Boolean,
      default: true
    },
    customWindowOpen: {  // 特定ドメインの時に同タブ起動
      type: Boolean,
      default: false
    }
  },
  beforeCreate() {
  },
  methods: {
    pageMove() {
      this.$emit('close-window');
      const isIOS = /[ \(]iP/.test(navigator.userAgent);
      // targetUrlに特定ドメイン(文字列)が含まれている時に同タブで起動
      // || VRに移動するときは別タブ起動しない(クラッシュしやすいため)
      if (this.customWindowOpen || (isIOS && this.targetUrl.match('vr-lite.net'))) {
        location.href = this.targetUrl;
      } else if (!this.windowOpen) {
        // 別タブ起動しないとき
        location.href = this.targetUrl;
        location.reload();
      } else {
        // 別タブ起動
        window.open(this.targetUrl);
      }
    }
  }
}
</script>

<style scoped>
.confirm-window {
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  padding: 1em;
  background:#fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.confirm-window p {
  margin: 0.5em;
}
.target-title {
  font-weight: bold;
}
.target-url {
  font-size: 0.8em;
  word-break: break-all;
  padding-bottom: 20px;
}
.move-button {
  border: none;
  color: #ffffff;
  padding: 4px 8px;
  margin: 0.25em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 120px;
  height: 45px;
  border-radius: 10px;
  font-weight: 900;
}
.ok-col  {
  background-color: #F18D5F;
}
.cancel-col  {
  background-color: #a9a9a9;
}
</style>
