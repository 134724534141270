export default {
  methods: {
    // ページタイトルと説明を書き換え
    changePageContent() {
      document.title = this.jsonData.title + ' | ' + this.title;
      document.querySelector('meta[name="description"]').setAttribute('content', this.description);
      this.updateOgpContent();
    },

    // 特定のページにnoindex, nofollowのmetaタグを追加
    addNoIndexMeta() {
      const metaTag = document.createElement('meta');
      metaTag.name = 'robots';
      metaTag.content = 'noindex, nofollow';
      document.head.appendChild(metaTag);
    },

    // OGP関連のmeta contentを書き換え
    updateOgpContent() {
      if (this.title) {
        document.querySelector('meta[property="og:title"]').setAttribute('content', this.jsonData.title + ' | ' + this.title); // OGP:タイトル
      }
      if (this.description) {
        document.querySelector('meta[property="og:description"]').setAttribute('content', this.description); // OGP:説明文
      }
      // siteNameが設定されている場合はog:site_nameを書き換え
      if (this.siteName) {
        document.querySelector('meta[property="og:site_name"]').setAttribute('content', this.siteName); // OGP:サイト名
      } else {
        document.querySelector('meta[property="og:site_name"]').setAttribute('content', this.title); // OGP:サイト名
      }
      // og:urlを書き換え
      const currentUrl = window.location.href
      const baseUrl = currentUrl.split('?')[0]; // URLのパラメータを除外
      document.querySelector('meta[property="og:url"]').setAttribute('content', baseUrl); // OGP:URL

      // ogImagePathが設定されている場合はog:imageを書き換え
      try {
        if (typeof this.ogImagePath === 'function') {
          document.querySelector('meta[property="og:image"]').setAttribute('content', this.ogImagePath()); // OGP:画像
        } 
      } catch (error) {
        console.error('Error updating OGP IMAGE content:', error);
      }

      // 子ページの時はog:typeを"article"にする
      if (this.pageId !== undefined) {
        document.querySelector('meta[property="og:type"]').setAttribute('content', 'article'); // OGP:サイトの種類
      }
    }
  }
}